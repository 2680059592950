const Stier = {
  hovedsiden: "/",

  hva: "/hva",
  tester: "/tester",
  fagomr: "/fagområder",
  system: "/utdanningssystemet",
  hjerneHjerte: "/hjerne-eller-Hjerte",
  friAar: "/friår",

  hvor: "/hvor",
  utland: "/utlandet",
  uniHskole: "/universitet-høyskole",
  privat: "/private-høyskoler",
  utlandNorsk: "/utlandet-på-norsk",

  opptakskrav: "/opptakskrav",
  karaktersnitt: "/regne-karaktersnitt",

  hvordan: "/hvordan",
  soknadNorge: "/søknad-norge",
  soknadUtland: "/søknad-utland",
  opptakskvoter: "/opptakskvoter",
  dinkvote: "/din-opptakskvote",

  støtte: "/økonomisk-støtte",
  spørsmål: "/spørsmål-svar",
};

export default Stier;
