import React from 'react';
import {GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import Stier from "../../navigator/Stier";
import NavigFooter from "../../navigator/NavigFooter";

const knappBredde = "80%";


const Fagomr = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.fagomr}/>
        <div className={"body"}>
            <div className="infoTekst">
                <p>
                    Bli godt kjent med innholdet i studiene du vurderer.
                    Det er viktig at du vet hvilke emner som inngår i studiet, og hvilke jobber
                    du kvalifiserer deg til.
                </p>
                <p>
                    Under finner du oversikt over hvordan studier er delt
                    inn i ulike fagområder.
                </p>
            </div>

            <div>
                <UrlKnapp url={'https://utdanning.no/interesseoversikt#/utdanninger/'} navn={'utdanning.no: interesseoversikt'}
                          knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.uio.no/studier/program/#topic=&type='} navn={'UiO: Studier'}
                          knappBredde={knappBredde}/>
            </div>
        </div>
        <NavigFooter forrige={Stier.hva}/>
    </div>

export default Fagomr;
