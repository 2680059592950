import React from 'react';
import {GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import Stier from "../../navigator/Stier";
import {Scrollbars} from "react-custom-scrollbars-2";

const knappBredde = "45%";

const Utland = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvor.utland}/>
        <div className={"body"}>
            <Scrollbars>
                <h2>
                    Velger du å studere i utlandet finnes det mange nettsteder som gir deg oversikt
                    over hele tilbudet.
                </h2>
                <p className="infoTekst">
                    Ansa er en nøytral organisasjon som har oversikt over studier over hele verden.
                </p>
                <UrlKnapp url={'https://www.ansa.no'} navn={'Ansa'} knappBredde={knappBredde}/>

                <h2>Private aktører</h2>
                <p className="infoTekst">
                    Kilroy samarbeider med ulike universitet i hele verden.
                </p>
                <UrlKnapp url={'https://www.kilroy.no'} navn={'Kilroy'} knappBredde={knappBredde}/>
                <p className="infoTekst">
                    Sonor hjelper deg med å søke opptak til studier i 6 forskjellige land.
                </p>
                <UrlKnapp url={'https://www.sonor.no'} navn={'Sonor'} knappBredde={knappBredde}/>
                <p className="infoTekst">
                    Acrossthepond har spesialisert seg på Storbritannia.
                </p>
                <UrlKnapp url={'https://www.studyacrossthepond.com/'} navn={'Acrossthepond'} knappBredde={knappBredde}/>
                <p className="infoTekst">
                    Bjorknes høyskole samarbeider hovedsakelig med universitet i Sentral-Europa og Australia.
                </p>
                <UrlKnapp url={'https://bjorkneshoyskole.no/studier/'} navn={'Bjørknes høyskole: studier'}
                          knappBredde={"70%"}/>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvor}/>
    </div>;

export default Utland;
