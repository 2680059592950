import React from 'react';
import {GlobalTekst, Header, VimeoEmbed} from '../../common';
import Stier from '../../navigator/Stier';
import NavigFooter from '../../navigator/NavigFooter';
import UrlKnapp from "../../common/UrlKnapp";
import {Scrollbars} from "react-custom-scrollbars-2";


const OpptaksKvoter = () =>

    <div className={"page"}>
        <Header tekst={GlobalTekst.hvordan.opptakskvoter}/>
        <div className={"body"}>
            <Scrollbars>
                <p className={"infoTekst"}>
                    Det finnes to søkerkvoter. En for førstgangsvitnemål og en ordinærkvote.
                </p>
                <p className={"infoTekst"}>
                    Dersom du har et førstegangsvitnemål, søker du i denne kvoten tom det året du
                    fyller 21.
                </p>
                <p className={"infoTekst"}>
                    Dersom du forbedrer karakterene dine, tar høyere
                    utdanning og blir eldre, søker du samtidig i ordinærkvote.
                </p>
                <p className={"infoTekst"}>
                    Frem til du fyller 22 søker du derfor automatisk i begge kvoter.
                </p>

                <h2>Se nærmere forklaring</h2>
                <UrlKnapp
                    url={'https://www.samordnaopptak.no/info/opptak/opptakskvoter/kvote-for-forstegangsvitnemal-.html'}
                    navn={'Samordna opptak: Opptakskvoter'}
                    knappBredde={"85%"}
                />

                <VimeoEmbed videoId={"203115478"}/>

                <div className="emptyLine"></div>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvordan}/>
    </div>;

export default OpptaksKvoter;
