import React from 'react';
import {EkspanderbarTekst, GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import Stier from "../../navigator/Stier";
import NavigFooter from "../../navigator/NavigFooter";
import '../7trinnStyles.css';
import {Scrollbars} from "react-custom-scrollbars-2";

const knappBredde = "70%";

const Tester = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.tester}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p>
                        Tester kan gi nyttig veiledning i valgprosessen, men de gir deg ingen fasit.
                    </p>
                    <p>
                        Husk at disse testene kun kan gi deg forslag til hvilke utdanninger du bør vurdere.
                        De kan gjøre deg bevisst på dine ferdigheter og egenskaper og utvide dine kunnskaper
                        om ulike yrkesfelt.
                    </p>
                </EkspanderbarTekst>
                <div className={"container"}>
                    <UrlKnapp url={'https://www.studentum.no/EducationTest/Start/8'} navn={'Studentum: Utdanningstest'}
                              knappBredde={knappBredde}/>
                    <UrlKnapp url={'https://utdanning.no/jobbkompasset/'} navn={'Utdanning: Jobbkompasset'}
                              knappBredde={knappBredde}/>
                    <h2>
                        For realfagsstudier
                    </h2>
                    <UrlKnapp url={'https://www.velgriktig.no'} navn={'velgriktig.no'} knappBredde={knappBredde}/>
                </div>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hva}/>
    </div>

export default Tester;
