import React, {ChangeEvent, useEffect, useState} from 'react';
import './NotatModal.css';
import LukkImg from '../asset/img/close-48.svg'

export default function Notat() {
    
    const [tekst, setTekst] = useState('');

    useEffect(() => {
        if(tekst === ''){
            const data:string | null = localStorage.getItem('notat');
            let obj = '';
            if(data !== null){
                obj = JSON.parse(data);
            }
            setTekst(obj)
        }
    }, [setTekst]);


    function handleInputChange (e: ChangeEvent<HTMLTextAreaElement>) {
        setTekst(e.target.value);
    }

    function handleSubmit() {
        localStorage.setItem('notat', JSON.stringify(tekst));
        setTekst('')
    }

    return (
        <form onSubmit={handleSubmit}>
            <textarea value={tekst} onChange={handleInputChange}/>
            <div className={"notatFooterBar"}>
                <button className={"notatButton"} type="submit" value="Lukk" >
                    <img style={{alignSelf: 'center'}} src={LukkImg} alt={'lukk'}/>
                </button>
            </div>
        </form>
    );
}