import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

interface Props {
    videoId: string;
}

const vimeoWidth = window.innerWidth;

export const VimeoEmbed = (props: Props) => (
    <Vimeo className="vimeo" width={vimeoWidth} playsInline={false} video={props.videoId} />
);
