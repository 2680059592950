import React from 'react';
import './Header.css';

interface Props{
    tekst: string
}

const Header = (props:Props) =>

    <div className="header">
        <h1> {props.tekst} </h1>
    </div>;

export {Header};

