"use strict";

import {forbedretSkolePoeng, oppdaterKnapper, oppdaterTekster} from './ValgFunksjoner';
import {erGrønn, erGul, erRød, Farge, Knapp, Tekst} from "./Typer";

export function profilUnder22Aar(knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>) {
    switch (knappIndex) {
        case 3://bestått videregående skole
            bestattVDG(knapper, tekster);
            break;
        case 4://merket som førstegangsvitnemål
            forsteGangsVitnemal(knapper, tekster);
            break;
        case 5:
        case 6:
            forbedretSkolePoeng(knapper, tekster);
            break;
        default:
    }
}

function bestattVDG(knapper:Array<Knapp>, tekster:Array<Tekst>) {
    oppdaterKnapper(knapper, [4, 5, 6], true, Farge.Gul)
    if (erGul(knapper[3])) {
        return;
    } if (erRød(knapper[3])) {
        oppdaterKnapper(knapper, [4, 5, 6], false, Farge.Gul)
        oppdaterTekster(tekster, [3, 4, 5, 7], false)
        tekster[2].vis = true;
        return;
    } if (erGrønn(knapper[3])) {
        oppdaterKnapper(knapper, [4, 5, 6], true, Farge.Gul)
        tekster[2].vis = false;
    }
}

function forsteGangsVitnemal(knapper:Array<Knapp>, tekster:Array<Tekst>) {
    if (erGrønn(knapper[4])) {
        tekster[3].vis = false;
        tekster[4].vis = true;
    } else if (erRød(knapper[4])) {
        tekster[3].vis = true;
        tekster[4].vis = false;
    } else {
        return;
    }
}
