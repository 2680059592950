import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import Scrollbars from 'react-custom-scrollbars-2';

import SvarModalFooter from './SvarModalFooter';
import './SpørsmålOgSvar.css'

interface Props {
    isDark: boolean,
    spørsmål: string,
    children: any
}

export default function SvarModal(props: Props) {

    const [modalSynlig, setModalSynlig] = useState(false);
    const dark = getDark();

    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);

    function getDark() {
        return props.isDark ? "darkRow" : "";
    }

    return (
        <>
            <ReactModal
                isOpen={modalSynlig}
                className={"page"}
            >
                <div className={"svarHeader"}>
                    <p> {props.spørsmål} </p>
                </div>
                <div className={"svarBody"}>
                    <Scrollbars>
                        {props.children}
                    </Scrollbars>
                </div>
                <SvarModalFooter onClick={() => {
                    setModalSynlig(false)
                }}
                />
            </ReactModal>

            <div className={"modalLink " + dark}
                 onClick={() => {
                     setModalSynlig(true)
                 }}
            >
                <p className={"linkText"}> {props.spørsmål} </p>
            </div>
        </>
    );

}
