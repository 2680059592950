import React from 'react';
import {EkspanderbarTekst, GlobalTekst, Header} from "../../common";
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import Stier from "../../navigator/Stier";
import {Scrollbars} from "react-custom-scrollbars-2";

const litenKnapp = "30%";

const Utdanningssystem = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.system.overskrift}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p>
                        Det er lurt å bli kjent med strukturen på høyere utdanning, slik at du blir kjent med
                        omfanget av studiene du vurderer.
                    </p>
                    <p>
                        Tradisjonelt har utdanningene på universitetene gitt deg en grad innenfor et bestemt
                        teoretisk fagområde. Du har derfor ikke utdannet deg til et bestemt yrke
                        (med unntak av profesjonsutdanningene). Gradstrukturen på universitetet er fleksibelt
                        og det er mulig å endre kurs underveis.
                    </p>
                    <p className="tekst">
                        Høyskolene har et bredt tilbud av utdanninger som fører frem til konkrete yrker, og
                        det er ofte praksis underveis i studiet.
                        (eksempler fysioterapeut, ingeniør, sykepleier, sosionom).
                    </p>
                </EkspanderbarTekst>
                <h2>
                    Oversikt over utdanningssystemet i Norge.
                </h2>
                <UrlKnapp
                    url={'https://utdanning.no/utdanningssystemet/#/'}
                    navn={'utdanning.no: utdanningssystemet'}
                    knappBredde={"85%"}
                />
                <h2>
                    Eksempel på oppbygging av en bachelorgrad, mastergrad og profesjonsutdanning.
                </h2>
                <UrlKnapp url={'https://www.uio.no/studier/program/historie/oppbygging/'} 
                    navn={'Bachelor'}
                    knappBredde={litenKnapp}/>
                <UrlKnapp url={'https://www.uio.no/studier/program/historie-master/oppbygging/'} 
                    navn={'Master'}
                    knappBredde={litenKnapp}/>
                <UrlKnapp url={'https://www.uio.no/studier/program/psyk-prof/oppbygging/'} 
                    navn={'Profesjon'}
                    knappBredde={litenKnapp}/>
                <h2>
                    Eksempel på høyskoleutdanning.
                </h2>
                <UrlKnapp url={'https://www.oslomet.no/studier/hv/farmasi'} navn={'Bachelorstudium i farmasi'}
                          knappBredde={"68%"}/>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hva}/>
    </div>

export default Utdanningssystem;
