import React from 'react';
import {GlobalTekst, Header} from '../../common';
import Stier from "../../navigator/Stier";
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import {Scrollbars} from "react-custom-scrollbars-2";

const knappBredde = "55%";

const SoknadUtland = () =>

    <div className={"page"}>
        <Header tekst={GlobalTekst.hvordan.soknadUtland}/>
        <div className={"body"}>
            <Scrollbars>
                <p className="infoTekst">
                    Hvert enkelt land har ulike frister.
                </p>

                <div className={"container"}>
                    <UrlKnapp url={'https://www.ansa.no/Studier-i-utlandet/For/praktisk-informasjon/soknadsfrister/'}
                              navn={'Ansa: Søknadsfrister'} knappBredde={knappBredde}/>

                    <p className={"infoTekst"}>
                        I søknaden til utlandsstudier kreves det som regel at du tar språktest, oversetter vitnemål,
                        og skriver motivasjonsbrev.
                    </p>
                    <p className="infoTekst">
                        Ansa gir deg veiledning om hva som kreves.
                    </p>
                    <UrlKnapp url={'https://www.ansa.no/Studier-i-utlandet/For/praktisk-informasjon/motivasjonsbrev/'}
                              navn={'Motivasjonsbrev'} knappBredde={knappBredde}/>
                    <UrlKnapp url={'https://www.ansa.no/Studier-i-utlandet/For/praktisk-informasjon/spraktester/'}
                              navn={'Språktester'} knappBredde={knappBredde}/>
                    <UrlKnapp url={'https://www.ansa.no/Studier-i-utlandet/For/praktisk-informasjon/opptakstester/'}
                              navn={'Opptakstester'} knappBredde={knappBredde}/>
                    <UrlKnapp
                        url={'https://www.ansa.no/Studier-i-utlandet/For/praktisk-informasjon/vitnemal-og-attester/'}
                        navn={'Attester og vitnemål'} knappBredde={knappBredde}/>
                </div>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvordan}/>
    </div>;

export default SoknadUtland;
