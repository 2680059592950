"use strict";

import {erGrønn, Farge, Knapp, Tekst, viseKnapp} from "./Typer";

export function oppdaterKnapper(knapper:Array<Knapp>, indexer:Array<number>, vis:boolean, svar:Farge) {
    indexer.forEach(function (val) {
        let knapp = knapper[val];
        knapp.vis = vis;
        knapp.farge = svar;
    });
}

export function resettKnappene(knapper:Array<Knapp>) {
    knapper.forEach(function (knapp) {
        knapp.vis = false;
        knapp.farge = Farge.Gul;
    });
}

export function oppdaterTekster(tekster:Array<Tekst>, indexer:Array<number>, vis:boolean) {
    indexer.forEach(function (val) {
        tekster[val].vis = vis;
    });
}

export function forbedretSkolePoeng(knapper:Array<Knapp>, tekster:Array<Tekst>) {
    if (erGrønn(knapper[5])) {
        tekster[6].vis = true;
    } else {
        tekster[6].vis = false;
    }
    if (erGrønn(knapper[6])) {
        tekster[8].vis = true;
    } else {
        tekster[8].vis = false;
    }
}

export function erPaStartsiden(knapper:Array<Knapp>) {
    return viseKnapp(knapper[0]) && viseKnapp(knapper[1]) && viseKnapp(knapper[2]);
}
