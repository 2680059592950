"use strict";

import {Farge, Knapp, Tekst} from "./Typer";

export function profil19Aar(knapper:Array<Knapp>, tekster:Array<Tekst>){
  switch (knapper[3].farge){ //Har bestått VDG
    case Farge.Grønn:
      tekster[9].vis=true;
      tekster[0].vis = false;
      break;
    case Farge.Rød:
      tekster[9].vis=false;
      tekster[0].vis=true;
      break;
    default :
      tekster[0].vis=false;
  }
}
