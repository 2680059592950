import React from 'react';
import '../common/CommonStyles.css';
import {useHistory} from "react-router";

interface Props {
    sti:string,
    tekst:string
}

function BodyNavigElem(props:Props) {
    const history = useHistory();

    return <div className="BodyNavig">
        <p className={"linkTekst"}
           onClick={() => {
               history.push(props.sti)
           }}>
            {props.tekst}
        </p>
    </div>;
}

export default BodyNavigElem


