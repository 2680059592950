"use strict";

import {profilUnder22Aar} from './ProfilUnder22Aar';
import {profilOver22Aar} from './ProfilOver22Aar';
import {profil19Aar} from './ProfilUnder19Aar';
import {erPaStartsiden, oppdaterKnapper} from './ValgFunksjoner';
import {erGrønn, Farge, Knapp, Tekst} from "./Typer";

export function synligeElementer(knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>) {
  if(!bytterProfil(knapper, knappIndex, tekster)){
    settProfil(knapper, knappIndex, tekster);
  }
}

const bytterProfil = (knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>) => {
    if(knappIndex > 2) {
      return false;
    }
    if(!erGrønn(knapper[knappIndex])){
      oppdaterKnapper(knapper, [0, 1, 2], true, Farge.Gul);
      oppdaterKnapper(knapper, [3, 4, 5, 6], false, Farge.Gul);
      gjemAlleTekster(tekster);
      return true;
  }
  return false;
}

export function gjemAlleTekster(tekster:Array<Tekst>){
  tekster.forEach(tekst => {
    tekst.vis = false;
    return tekst;
  });
}

function settProfil(knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>){
  if(erPaStartsiden(knapper)){
    settNyProfil(knapper, knappIndex, tekster);
  } else {
    brukSammeProfil(knapper, knappIndex, tekster);
  }
}

function settNyProfil(knapper:Array<Knapp>, index:number, tekster:Array<Tekst>){
  knapper[3].vis=true;//Du har bestått videregående skole?
  switch (index) {
    case 0:
      oppdaterKnapper(knapper, [1, 2], false, Farge.Gul)
      profil19Aar(knapper, tekster);
      break;
    case 1:
      oppdaterKnapper(knapper, [0, 2], false, Farge.Gul);
      profilUnder22Aar(knapper, index, tekster);
      break;
    case 2:
      oppdaterKnapper(knapper, [0, 1], false, Farge.Gul);
      profilOver22Aar(knapper, index, tekster);
  }
}

function brukSammeProfil(knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>){
  if(knapper[0].vis){
    profil19Aar(knapper, tekster);
  } else if(knapper[1].vis){
    profilUnder22Aar(knapper, knappIndex, tekster);
  } else {
    profilOver22Aar(knapper, knappIndex, tekster);
  }
}
