import React from 'react';
import {GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import Stier from "../../navigator/Stier";

const knappBredde = "45%";

const UtlandNorsk = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvor.utlandNorsk}/>
        <div className={"body"}>
            <p className="infoTekst">
                Dersom du ønsker å gjennomføre deler av studiet ditt i utlandet, og foretrekker
                undervisning på norsk finnes det mange tilbud.
            </p>
            <p className="infoTekst">
                Eksempler finner du her.
            </p>
            <UrlKnapp url={'https://gogateway.no'} navn={'gogateway.no'} knappBredde={knappBredde}/>
            <UrlKnapp url={'https://www.kulturakademiet.no/'} navn={'Kulturakademiet'} knappBredde={knappBredde}/>
            <UrlKnapp url={'https://www.gostudy.no/'} navn={'gostudy.no'} knappBredde={knappBredde}/>
        </div>
        <NavigFooter forrige={Stier.hvor}/>
    </div>;

export default UtlandNorsk;
