import React from 'react';
import {GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import Stier from "../../navigator/Stier";
import NavigFooter from "../../navigator/NavigFooter";

const knappBredde = "70%";

const HjerneHjerte = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.hjerneHjerte}/>
        <div className={"body"}>
            <div>
                <div className="infoTekst">
                    <p>
                        Hva er viktigst? Å velge studier etter interesser eller hvilken kompetanse
                        samfunnet etterspør?
                    </p>
                    <p>
                        Dette er et personlig valg, men et tips i valgprosessen er at du orienterer deg i
                        fremtidens arbeidsmarked.
                    </p>
                </div>
                <div>
                    <UrlKnapp
                        url={'https://www.nav.no/no/Lokalt/innlandet/relatert-informasjon/arbeidsmarkedsprognoser'}
                        navn={'Fremtidens arbeidsmarked'} knappBredde={knappBredde}/>
                </div>
            </div>
            <NavigFooter forrige={Stier.hva}/>
        </div>
    </div>;

export default HjerneHjerte;
