import React, {useState} from 'react';
import './CommonStyles.css';

interface TekstElem {
    children: any;
}

const merInfo = "Mer info";
const skjulInfo = "Mindre info";

export function EkspanderbarTekst(props:TekstElem) {

    const [visTekst, setVisTekst] = useState(false)
    const [infoStr, setInfoStr] = useState(merInfo)

    function infoTekst() {
        if (visTekst) {
            return <div className="infoTekst">
                {props.children}
            </div>;
        }
    }

    function skalViseTekst() {
        if (visTekst) {
            setVisTekst(false);
            setInfoStr(merInfo);
            return;
        }
        setVisTekst(true);
        setInfoStr(skjulInfo);
    }

    return (
        <div>
            <div className="linkFarge" onClick={() => {
                skalViseTekst()
            }}>
                <p className="infoLink">{infoStr}</p>
            </div>
            {infoTekst()}
        </div>
    );
}