import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import Notat from './Notat';

import editIkon64 from '../asset/img/notebook-64.svg'
import editIkon48 from '../asset/img/notebook-48.svg'

interface Props {
    hovedSide?:boolean
}

export default function NotatModal(props:Props) {

    const [modalVisible, setVisible] = useState(false);

    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);

    function hentModal() {
        return <ReactModal
            className="Modal"
            isOpen={modalVisible}
        >
            <div style={{"height": "90%"}}>
                <Notat/>
            </div>
        </ReactModal>
    }

    function hentNotatForHovedside() {
        return <button className="modalKnapp"
            onClick={() => {
                setVisible(true);
            }}>
            <img src={editIkon64} alt="Notater"/>
            {hentModal()}
        </button>;
    }

    function hentNotatForFooter() {
        return <button className={"notatFooterButton"}
                       onClick={() => {
                           setVisible(true);
                       }}>
            <img src={editIkon48}/>
            {hentModal()}
        </button>;
    }

    return (<>{props.hovedSide ? hentNotatForHovedside() : hentNotatForFooter()}</> );
}

