import React from 'react';
import YouTube from 'react-youtube';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {EkspanderbarTekst, GlobalTekst, Header} from "../../common";
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import Stier from "../../navigator/Stier";


const opts = {height: '100%', width: '100%', backgroundColor: 'black'}

const SoknadNorge = () =>

    <div className={"page"}>
        <Header tekst={GlobalTekst.hvordan.soknadNorge}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p className={"infoTekst"}>
                        Til private skoler søker du på skolens nettside.
                    </p>
                    <p className={"infoTekst"}>
                        Alle offentlige skoler søkes på via samordnaopptak. Frist 15.april.
                    </p>
                    <p className={"infoTekst"}>
                        På samordnaopptak kan du søke på 10 ulike
                        studier i prioritert rekkefølge.
                        Omprioriteringer kan gjøres frem til 1.juli, men ingen nye ønsker kan legges inn.
                    </p>
                </EkspanderbarTekst>

                <h2>
                    Se veiledning for søkerprosessen her:
                </h2>
                <UrlKnapp
                    url={'https://www.samordnaopptak.no/info/'}
                    navn={'samordnaopptak.no'}
                    knappBredde={'55%'}
                />

                <p className="infoTekst">
                    Om søknadsprosessen på video:
                </p>
                <div className="youtube">
                    <YouTube videoId={"Qw3kppv8gDE"} opts={opts}/>
                </div>

                <p className="infoTekst">
                    Dersom du har avsluttet vgs tidligere enn søkeåret må du i tillegg laste opp
                    dokumentasjon.
                </p>

                <p className="infoTekst">
                    Se fremgangsmåte her:
                </p>
                <div className="youtube">
                    <YouTube videoId={"OXR-ofTssTk"} opts={opts}/>
                </div>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvordan}/>
    </div>;

export default SoknadNorge;


