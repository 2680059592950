import React, {MouseEventHandler} from 'react';
import NotatModal from "../../notat/NotatModal";
import LukkImg from '../../asset/img/close-48.svg';

interface Props {
    onClick: MouseEventHandler
}

const SvarModalFooter = (props: Props) =>
        <div className={"navigFooterBar"}>
            <button className={"largeFooterButton"} onClick={props.onClick}>
                <img style={{alignSelf: 'center'}} src={LukkImg} alt={'lukk'}/>
            </button>
            <NotatModal/>
        </div>

export default SvarModalFooter;
