import React from 'react';
import { GlobalTekst, Header } from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import { Scrollbars } from "react-custom-scrollbars-2";

const knappBredde = "50%";

const Støtte = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.støtte.overskrift} />
        <div className={"body"}>
            <Scrollbars>
                <h2>Lånekassen</h2>
                <p className={"infoTekst"}>
                    Statens lånekasse for utdanning gir alle studenter en basis støtte
                    dersom studiet er godkjent.
                    Dette gjelder både for studier i utlandet og i Norge.
                </p>
                <h2>Se oversikt over regler for lån og stipend på lånekassen:</h2>
                <UrlKnapp url={'https://lanekassen.no/'} navn={'Lånekassen'} knappBredde={knappBredde} />

                <h2>Legater</h2>
                <p className={"infoTekst"}>
                    I tillegg er det mulig å søke på forskjellige legater.
                    Oversikt over hvilke legater som finnes finner du her:
                </p>
                <UrlKnapp url={'https://legathandboken.no/'} navn={'Legathåndboken'} knappBredde={knappBredde} />
            </Scrollbars>
        </div>
        <NavigFooter />
    </div>;

export default Støtte;
