import React from 'react';
import {Scrollbars} from "react-custom-scrollbars-2";
import {EkspanderbarTekst, GlobalTekst, Header, VimeoEmbed} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";

const knappBredde = "70%";

const Opptakskrav = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.opptakskrav.overskrift}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p className={"infoTekst"}>
                        Studiekompetanse kan man få på mange forskjellige måter. Fullført videregående
                        skole, 23/5 regel, eller ved realkompetanse.
                    </p>
                    <h2>
                        Generell/spesiell studiekompetanse.
                    </h2>
                    <p className={"infoTekst"}>
                        Noen studier krever enten en spesiell fagkombinasjon, eller en bestemt karakter
                        for å være kvalifisert søker. Det betegnes som spesiell studiekompetanse dersom
                        du har valgt fag som studiet du ønsker deg inn på har som krav.
                    </p>
                    <h2>
                        Poenggrenser.
                    </h2>
                    <p className={"infoTekst"}>
                        Poenggrenser er veiledende. Oversiktene viser antall poeng den svakeste søker hadde
                        forrige opptakssår.
                    </p>
                </EkspanderbarTekst>

                <p className={"infoTekst"}>
                    Her er en oversikt over studier med ulike opptakskrav, og en video om hvordan du finner kravene.
                </p>

                <h2>
                    Studier med spesiell studiekompetanse:
                </h2>
                <UrlKnapp url={'https://utdanning.no/tema/soknad_og_opptak/slik_far_du_studiekompetanse'}
                          navn={'utdanning.no: Regler for studiekompetanse'}
                          knappBredde={knappBredde}
                />

                <h2>
                    Studier med fagkrav:
                </h2>
                <UrlKnapp
                    url={'https://www.samordnaopptak.no/info/opptak/spesielle-opptakskrav/om-spesielle-opptakskrav/'}
                    navn={'Samordna opptak: spesielle opptakskrav'}
                    knappBredde={knappBredde}
                />
                <h2>
                    Hvordan finne kravene:
                </h2>

                <VimeoEmbed videoId={"170795657"} />

                <h2>
                    Oversikt over poenggrense:
                </h2>
                <UrlKnapp url={'https://www.samordnaopptak.no/info/opptak/poenggrenser/index.html'}
                          navn={'Samordna  opptak: Poenggrenser'}
                          knappBredde={knappBredde}
                />
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter/>
    </div>;

export default Opptakskrav;
