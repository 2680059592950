import React from "react";
import UrlKnapp from "../../common/UrlKnapp";

export const Svar1 = () =>
    <>
        <p className={"svar"}>
            Skolepoeng er karakterene dine fra vitnemålet ditt.
            <br/> Du konkurrerer deg inn på høyere utdanning med poengene.
        </p>
        <p className={"svar"}>
            Studiepoeng er ikke karakterpoeng, men et vektingssystem.
        </p>
    </>;

export const Svar2 = () =>
    <>
        <p className={"svar"}>
            Alle som har et førstegangsvitnemål (fullført og bestått etter tre år, med noen unntak) konkurrerer seg inn
            med dette hvert år tom det året de fyller 21.
            <br/> 50 % av plassene er reservert til denne kvoten.
        </p>
        <p className={"svar"}>
            Året etter avgangsåret på videregående konkurrerer de i tillegg i ordinær kvote.
            <br/> I ordinær kvote blir eventuelle karakter forbedringer, alderspoeng og tilleggspoeng lagt til.
        </p>
    </>;

export const Svar3 = () =>
    <>
        <p className={"svar"}>
            Vitnemålet blir satt sammen av alle fag du avslutter hvert år. Hvilke dette
            er kommer an på hvilket utdanningsprogram du har gått på.
        </p>
        <p className={"svar"}>
            Eksempel på vitnemål fra studieforberedende finner du her:
        </p>
        <UrlKnapp
            url={'https://www.udir.no/eksamen-og-prover/dokumentasjon/vitnemal-og-kompetansebevis/eksempler-pa-vitnemal---studieforberedende/'}
            navn={'Vitnemål studieforberedende'} knappBredde={"75%"}/>
        <p className={"svar"}>
            Fra yrkesfaglig utdanning her:
        </p>
        <UrlKnapp
            url={'https://www.udir.no/eksamen-og-prover/dokumentasjon/vitnemal-og-kompetansebevis/eksempler-pa-vitnemal---yrkesfag/'}
            navn={'Vitnemål yrkesfag'} knappBredde={"75%"}/>
    </>;

export const Svar4 = () =>
    <>
        <p className={"svar"}>
            Du kan forbedre karakterene dine ved å ta opp igjen fag som privatist.
            Enten ved å lese selv, eller følge undervisning på en privatskole som tilbyr
            undervisning i fagene du ønsker.
        </p>
        <p className={"svar"}>
            Oppmelding til privatisteksamen gjøres her:
        </p>
        <UrlKnapp url={'https://www.privatistweb.no/prweb/start.action?index=true'}
                  navn={'privatistweb.no'}
                  knappBredde={"45%"}/>
        <p className={"svar"}>
            Frist for oppmelding høstsemester: 15.september.
        </p>
        <p className={"svar"}>
            Frist for oppmelding vårsemester: 1.februar.
        </p>
        <p className={"svar"}>
            Husk at forbedringer etter fullført VGS ikke kan benyttes på et førstegangsvitnemål,
            men benyttes i ordinær kvote.
        </p>
    </>;

export const Svar5 = () =>
    <>
        <p className={"svar"}>
            Nei, du blir poengberegnet og får ikke tilbud om studieplass dersom du kun har søkt studier du ikke har nok
            poeng til å komme inn på.
        </p>
    </>;

export const Svar6 = () =>
    <>
        <p className={"svar"}>
            Noen privatskoler har rullerende inntak. Ta kontakt med skolen etter 15.4 for svar.
        </p>
        <p className={"svar"}>
            Skoler som er søkbare via samordnaopptak kan du ikke søke på etter 15.4, men du har mulighet til å søke på lledige studieplasser etter 20.juli. Sjekk ledige plasser som legges ut 20.7.
        </p>
        <p className={"svar"}>
            For skoler i utlandet, sjekk oversikten over frister til de ulike landene.
        </p>
    </>;

export const Svar7 = () =>
    <>
        <p className={"svar"}>
            Dersom du avslutter vgs i søkeråret skal du kun sjekke at vitnemålet ditt er kommet inn elektronisk innen 1.7.
        </p>
        <p className={"svar"}>
            Hvis du har gjennomført utdanning som gir deg poeng må du sende inn dokumentasjon som beskrevet på søkersiden til samordnaopptak.        </p>
        <p className={"svar"}>
            For private skoler må du sende inn dokumentasjon fortløpende.
        </p>
    </>;



