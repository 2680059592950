import React from 'react';

import './NavigFooter.css';
import {useHistory} from "react-router";
import Stier from "./Stier";
import NotatModal from "../notat/NotatModal";
import TilbakeLogo from '../asset/img/back-icon-48.svg'
import HeimLogo from '../asset/img/home-icon-48.svg'

interface Props {
    forrige?: string
}

function NavigFooter(props: Props) {

    const history = useHistory();

    const navigerHjem = () => {
        history.push(Stier.hovedsiden);
    }

    const navigerTilbake = () => {
        if (props.forrige !== undefined) {
            history.push(props.forrige);
        }
    }

    return <div className={"navigFooterBar"}>
        {props.forrige !== undefined ?
            <>
                <button className={"smallFooterButton"} onClick={navigerTilbake}>
                    <img src={TilbakeLogo} alt={props.forrige}/>
                    </button>
                <button className={"smallFooterButton"} onClick={navigerHjem}><img src={HeimLogo} alt={Stier.hovedsiden}/></button>
            </> :
            <button className={"largeFooterButton"} onClick={navigerHjem}><img src={HeimLogo} alt={Stier.hovedsiden}/></button>
        }
        <NotatModal/>
    </div>
}

export default NavigFooter;