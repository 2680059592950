import React from 'react';
import './UrlKnapp.css';

interface Props {
    url:string,
    navn:string,
    knappBredde:string
}

function UrlKnapp(props:Props) {

    const åpneLenke = () => {
        window.open(props.url);
    }

    return <div className={"urlKnappContainer"} >
        <button className={"urlKnapp"} style={{"width" : props.knappBredde}} onClick={åpneLenke}>
                <p className={'KnappTekst'}>{props.navn}</p>
        </button>
    </div>
}

export default UrlKnapp;
