import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {EkspanderbarTekst, GlobalTekst, Header} from '../../common';
import NavigFooter from '../../navigator/NavigFooter';
import BodyNavigElem from '../../navigator/BodyNavigElem';
import Stier from '../../navigator/Stier';

import "intersection-observer";

import '../../common/CommonStyles.css';
import '../7trinnStyles.css';


const Hva = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.overskrift}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p>
                        Det finnes ingen som kan gi deg et konkret svar på hva du bør studere. Du må gjøre
                        en innsats for å informere deg og bli kjent med ulike fagområder og arbeidsfelt.
                    </p>
                    <p>
                        Dine interesser, ferdigheter, verdier og motivasjon er avgjørende faktorer for ditt
                        valg. I tillegg bør du skaffe deg en oversikt over prognoser for fremtidens arbeidsmarkedet.
                    </p>
                </EkspanderbarTekst>
                <div>
                    <h2>
                        Følgende sider gir deg mer veiledning i valgprosessen:
                    </h2>
                </div>
                <BodyNavigElem sti={Stier.tester} tekst={GlobalTekst.hva.tester}/>
                <BodyNavigElem sti={Stier.fagomr} tekst={GlobalTekst.hva.fagomr}/>
                <BodyNavigElem sti={Stier.system} tekst={GlobalTekst.hva.system.navTekst}/>
                <BodyNavigElem sti={Stier.hjerneHjerte} tekst={GlobalTekst.hva.hjerneHjerte}/>
                <BodyNavigElem sti={Stier.friAar} tekst={GlobalTekst.hva.friAar}/>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter/>
    </div>;

export default Hva;


