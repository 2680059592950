import React from 'react';
import {GlobalTekst, Header} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import Stier from "../../navigator/Stier";
import NavigFooter from "../../navigator/NavigFooter";
import {Scrollbars} from "react-custom-scrollbars-2";

const knappBredde = "55%";

const UniHskole = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvor.uniHskole}/>
        <div className={"body"}>
            <Scrollbars>
                <p className="infoTekst">
                    Her finner du oversikt over alle landets universitet og høyskoler.
                    I tillegg finner du oversikt over utdanninger på fagskolene og i forsvaret.
                </p>
                <p className="infoTekst">
                    Vet du hva du vil studere, men ikke hvor tilbudet finnes.
                    Se linkene under:
                </p>
                <UrlKnapp url={'https://sok.samordnaopptak.no/#/studies'} navn={'samordnaopptak.no'}
                          knappBredde={knappBredde}/>
                <UrlKnapp
                    url={'https://www.studievalg.no/studier#alle-fagomrader,alle-niva,alle-sted,alle-yrke,,0,50'}
                    navn={'studievalg.no'} knappBredde={knappBredde}/>

                <h2>
                    Universitet og høyskoler
                </h2>
                <p className="infoTekst">
                    Her er en oversikt over alle universitet og høyskoler i landet:
                </p>
                <UrlKnapp
                    url={'https://www.regjeringen.no/no/dep/kd/org/etater-og-virksomheter/underliggende-etater/statlige-universiteter-og-hoyskoler/id434505/'}
                    navn={'Statlige universitet og høyskoler'} knappBredde={"80%"}/>
                <h2>
                    Fagskoler
                </h2>
                <p className="infoTekst">
                    Fagskolene tilbyr korte og yrkesrettede utdanninger som bygger på videregående skole.
                </p>
                <UrlKnapp url={'https://fagskolene.no/'} navn={'fagskolene.no'} knappBredde={knappBredde}/>

                <h2>
                    Forsvaret
                </h2>
                <p className="infoTekst">
                    Forsvaret har et bredt utdanningstilbud på ulike nivåer.
                </p>
                <UrlKnapp url={'https://forsvaret.no/'} navn={'forsvaret.no'} knappBredde={knappBredde}/>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvor}/>
    </div>;

export default UniHskole;
