import React from 'react';
import { MainStyles } from './index';

export const merInfo = "Vis info";
const skjulInfo = "Skjul info";


const infoTekst = (visTekst, tekst) => {
  if(visTekst){
    return <p style={MainStyles.tekst} >{tekst}</p>;
  }
}
export { infoTekst };

const viseTekst = (visTekst) => {
  if(visTekst){
    return {visTekst : false, visInfoStr : merInfo};
  } else {
    return {visTekst : true, visInfoStr : skjulInfo};
  }
}
export { viseTekst };
