import React from 'react';
import {GlobalTekst, Header} from '../../common';
import Stier from "../../navigator/Stier";
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";

const Private = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvor.privat}/>
        <div className={"body"}>
            <p className="infoTekst">
                Det finnes noen private utdanningsinstitusjoner som tilbyr studier som er godkjent av
                NOKUT ( Nasjonalt organ for kvalitet i utdanningen )
            </p>
            <UrlKnapp
                url={'https://www.nokut.no/'} navn={'nokut.no'} knappBredde={"35%"}
            />
            <p className="infoTekst">
                Private skoler har ofte egne opptakskrav og søknadsprosess.
            </p>
        </div>

        <NavigFooter forrige={Stier.hvor}/>
    </div>;

export default Private;
