import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Stier from './Stier';

import Hovedsiden from '../hovedside/Hovedsiden';
import Hva from '../7-trinn/1-hva/Hva';
import Tester from '../7-trinn/1-hva/Tester';
import Fagomr from '../7-trinn/1-hva/Fagomr';
import Utdanningssystem from '../7-trinn/1-hva/Utdanningssystem';
import HjerneHjerte from '../7-trinn/1-hva/HjerneHjerte';
import FriAar from '../7-trinn/1-hva/FriAar';

import Hvor from '../7-trinn/2-hvor/Hvor';
import Utland from '../7-trinn/2-hvor/Utland';
import UniHskole from '../7-trinn/2-hvor/UniHskole';
import Private from '../7-trinn/2-hvor/Private';
import UtlandNorsk from '../7-trinn/2-hvor/UtlandNorsk';

import Opptakskrav from '../7-trinn/3-opptakskrav/Opptakskrav';
import Karaktersnitt from '../7-trinn/4-karaktersnitt/Karaktersnitt';

import Hvordan from '../7-trinn/5-hvordan/Hvordan';
import SoknadNorge from '../7-trinn/5-hvordan/SoknadNorge';
import OpptaksKvoter from '../7-trinn/5-hvordan/OpptaksKvoter';
import MittOpptak from '../7-trinn/5-hvordan/mittOpptak/MittOpptak';
import SoknadUtland from '../7-trinn/5-hvordan/SoknadUtland';

import Støtte from '../7-trinn/6-støtte/Støtte';
import SpørsmålOgSvar from '../7-trinn/7-spørsmål/SpørsmålOgSvar';


export default class MinUtdanningNavigator extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={Stier.hovedsiden} component={Hovedsiden}/>

                    <Route path={Stier.hva} component={Hva}/>
                    <Route path={Stier.tester} component={Tester}/>
                    <Route path={Stier.fagomr} component={Fagomr}/>
                    <Route path={Stier.system} component={Utdanningssystem}/>
                    <Route path={Stier.hjerneHjerte} component={HjerneHjerte}/>
                    <Route path={Stier.friAar} component={FriAar}/>

                    <Route path={Stier.hvor} component={Hvor}/>
                    <Route path={Stier.utland} component={Utland}/>
                    <Route path={Stier.utlandNorsk} component={UtlandNorsk}/>
                    <Route path={Stier.uniHskole} component={UniHskole}/>
                    <Route path={Stier.privat} component={Private}/>

                    <Route path={Stier.opptakskrav} component={Opptakskrav}/>
                    <Route path={Stier.karaktersnitt} component={Karaktersnitt}/>

                    <Route path={Stier.hvordan} component={Hvordan}/>
                    <Route path={Stier.soknadNorge} component={SoknadNorge}/>
                    <Route path={Stier.soknadUtland} component={SoknadUtland}/>
                    <Route path={Stier.opptakskvoter} component={OpptaksKvoter}/>
                    <Route path={Stier.dinkvote} component={MittOpptak}/>

                    <Route path={Stier.støtte} component={Støtte}/>
                    <Route path={Stier.spørsmål} component={SpørsmålOgSvar}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
