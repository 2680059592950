import React from 'react';
import {EkspanderbarTekst, GlobalTekst, Header} from "../../common";
import BodyNavigElem from "../../navigator/BodyNavigElem";
import NavigFooter from "../../navigator/NavigFooter";
import Stier from "../../navigator/Stier";
import {Scrollbars} from "react-custom-scrollbars-2";

const Hvor = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvor.overskrift}/>
        <div className={"body"}>
            <Scrollbars>
                <EkspanderbarTekst>
                    <p className={"infoTekst"}>
                        Hva du velger å studere bestemmer til en viss grad hvor du kan studere.
                        Mange utdanninger finnes imidlertid på ulike studiesteder.
                        Hva bør du da vurdere? Byen størrelse, tilgjengelighet på hybler,
                        studiestedets rangering, aktivitetstilbud?
                    </p>
                    <p className={"infoTekst"}>
                        Vurder selv, og sjekk opp dette på skolenes nettsider.
                    </p>
                </EkspanderbarTekst>
                <p/>
                <BodyNavigElem
                    sti={Stier.utland}
                    tekst={GlobalTekst.hvor.utland}
                />
                <BodyNavigElem
                    sti={Stier.utlandNorsk}
                    tekst={GlobalTekst.hvor.utlandNorsk}
                />
                <BodyNavigElem
                    sti={Stier.uniHskole}
                    tekst={GlobalTekst.hvor.uniHskole}
                />
                <BodyNavigElem
                    sti={Stier.privat}
                    tekst={GlobalTekst.hvor.privat}
                />
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter/>
    </div>;

export default Hvor;
