import React from 'react';
import {EkspanderbarTekst, GlobalTekst, Header} from '../../common';
import {Scrollbars} from 'react-custom-scrollbars-2';
import Stier from "../../navigator/Stier";
import NavigFooter from "../../navigator/NavigFooter";
import UrlKnapp from "../../common/UrlKnapp";

const knappBredde = "70%";

const FriAar = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hva.friAar}/>
        <div className={"body"}>
            <Scrollbars>
            <>
                <EkspanderbarTekst>
                    <p>
                        Ønsker du et opphold mellom videregående og nye studier?
                        Det finnes mange spennende program å velge i. Folkehøyskoler, frivillig arbeid, språkkurs,
                        eller en kombinasjon av disse.
                    </p>
                </EkspanderbarTekst>
                <h2>
                    Få inspirasjon følgende lenker:
                </h2>
            </>
            <>
                <UrlKnapp url={'https://www.folkehogskole.no'} navn={'folkehogskole.no'} knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.aktivungdom.eu'} navn={'aktivungdom.eu'} knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.atlantis.no'} navn={'atlantis.no'} knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.goexplore.no'} navn={'goexplore.no'} knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.statravel.no'} navn={'statravel.no'} knappBredde={knappBredde}/>
                <UrlKnapp url={'https://www.ef.no/aya/'} navn={'ef.no'} knappBredde={knappBredde}/>
            </>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hva}/>
    </div>

export default FriAar;
