import React, {useState} from 'react';
import Stier from '../../../navigator/Stier';
import {GlobalTekst, Header} from '../../../common';
import NavigFooter from '../../../navigator/NavigFooter';
import './MittOpptak.css';
import Knapper from './Knapper.json';
import Tekster from './Tekster.json';
import {gjemAlleTekster, synligeElementer} from './SynligeElementer';
import {erPaStartsiden, oppdaterKnapper} from './ValgFunksjoner';
import {Scrollbars} from "react-custom-scrollbars-2";
import {endreFarge, erGrønn, erGul, Farge, Knapp, Tekst, viseTekst} from "./Typer";


function MittOpptak() {

    const [knapper, setKnapper] = useState<Array<Knapp>>(Knapper)
    const [tekster, setTekster] = useState(Tekster)
    let knapperKopi: Array<Knapp>;
    let teksterKopi: Array<Tekst>;

    return <div className={"page"}>
        <Header tekst={GlobalTekst.hvordan.mittOpptak}/>
        <div className="body">
            <Scrollbars className={"scroll"}>
                <div className={"elementer"}>
                    {visKnapper()}
                    {visTekstfelt()}
                    {visForklaring()}
                    {startKnapp()}
                </div>
            </Scrollbars>
        </div>
        <NavigFooter forrige={Stier.hvordan}/>
    </div>

    function visKnapper() {
        knapperKopi = [...knapper];

        return knapperKopi.filter(knapp => {
            return viseTekst(knapp);
        }).map(vises => {
            return knapp(vises)
        })
    }

    function knapp(knapp:Knapp) {
        const farge = settFarge(knapp);

        return <button
            className={"valgKnapp " + farge}
            key={knapp.index}
            onClick={() => {
                endreKnapp(knapp);
                synligeElementer(knapperKopi, knapp.index, teksterKopi);
                setKnapper(knapperKopi)
                setTekster(teksterKopi)
            }
            }
        >
            <p className={"valgKnappTekst bold"}>{knapp.txt}</p>
        </button>;
    }

    function settFarge(knapp:Knapp) {
        if (erGul(knapp)) {
            return 'gul';
        }
        return erGrønn(knapp) ? 'grønn' : 'rød';
    }

    function endreKnapp(knapp:Knapp) {
        switch (knapp.farge) {
            case Farge.Grønn:
                endreFarge(knapp, Farge.Rød);
                break;
            case Farge.Gul:
            default:
                endreFarge(knapp, Farge.Grønn);
        }
    }

    function visTekstfelt() {
        const synligeTekster = teksterSomSkalVises();
        if (synligeTekster.length > 0) {
            return <div className={"svarBoks"}>
                <p className="svarTekst bold">Svar :</p>
                {synligeTekster.map((elem) => (
                    <ol key={elem.index}><p className="svarTekst">{elem.txt}</p></ol>
                ))}
            </div>;
        }
    }

    function teksterSomSkalVises() {
        teksterKopi = [...tekster];
        return teksterKopi.filter(element => {
            return viseTekst(element);
        });
    }

    function visForklaring() {
        if (erPaStartsiden(knapper)) {
            return <div className={"svarBoks"}>
                <div>
                    <p className={"forklaring bold"}>
                        Trykk på knappene som gjelder for deg til du får ønsket farge:
                    </p>
                </div>
                <div className="forklaringRad">
                    <div className={"sirkel gul"}/>
                    <p className="forklaring">Du har ikke valgt.</p>
                </div>
                <div className="forklaringRad">
                    <div className={"sirkel grønn"}/>
                    <p className="forklaring">Du har valgt ja.</p>
                </div>
                <div className="forklaringRad">
                    <div className={"sirkel rød"}/>
                    <p className="forklaring">Du har valgt nei.</p>
                </div>
            </div>;
        }
    }

    function startKnapp() {
        if (erPaStartsiden(knapperKopi)) {
            return;
        }
        return <div className={"tilStartKnappContainer"}>
            <button
                className={"tilStartKnapp gul"}
                onClick={() => {
                    oppdaterKnapper(knapperKopi, [0, 1, 2], true, Farge.Gul)
                    oppdaterKnapper(knapperKopi, [3, 4, 5, 6], false, Farge.Gul)
                    gjemAlleTekster(teksterKopi);
                    setKnapper(knapperKopi);
                    setTekster(teksterKopi);
                }
                }
            >
                <p className={"valgKnappTekst"}>Til start</p>
            </button>
        </div>;
    }
}

export default MittOpptak;