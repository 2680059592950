import React from 'react';
import Stier from '../../navigator/Stier';
import {GlobalTekst, Header} from '../../common';
import NavigFooter from '../../navigator/NavigFooter';
import BodyNavigElem from '../../navigator/BodyNavigElem';

const Hvordan = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.hvordan.overskrift}/>
        <div className={"body"}>
            <BodyNavigElem sti={Stier.soknadNorge} tekst={GlobalTekst.hvordan.soknadNorge}/>
            <BodyNavigElem sti={Stier.soknadUtland} tekst={GlobalTekst.hvordan.soknadUtland}/>
            <BodyNavigElem sti={Stier.opptakskvoter} tekst={GlobalTekst.hvordan.opptakskvoter}/>
            <BodyNavigElem sti={Stier.dinkvote} tekst={GlobalTekst.hvordan.mittOpptak}/>
        </div>
        <NavigFooter/>
    </div>;

export default Hvordan;
