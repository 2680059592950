import React from 'react';
import {GlobalTekst, Header} from '../../common';

import SvarModal from './SvarModal';
import NavigFooter from "../../navigator/NavigFooter";
import Scrollbars from 'react-custom-scrollbars-2';
import {Svar1, Svar2, Svar3, Svar4, Svar5, Svar6, Svar7} from "./Svar";

const SpørsmålOgSvar = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.spørsmål.overskrift}/>
        <Scrollbars>
        <div className={"body"}>

            <p className={"veiledning"}>
                (Trykk på spørsmål for svar)
            </p>

            <SvarModal isDark={true} spørsmål={"Hva er forskjell på skolepoeng og studiepoeng?"}>
                <Svar1/>
            </SvarModal>
            <SvarModal isDark={false} spørsmål={"Hva er forskjellen på førstegangs vitnemålskvote og ordinærkvote?"}>
                <Svar2/>
            </SvarModal>
            <SvarModal isDark={true} spørsmål={"Hvilke karakterer kommer på vitnemålet mitt?"}>
                <Svar3/>
            </SvarModal>
            <SvarModal isDark={false} spørsmål={"Hvordan forbedrer jeg karakterene mine?"}>
                <Svar4/>
            </SvarModal>
            <SvarModal isDark={true} spørsmål={"Er jeg sikret en studieplass?"}>
                <Svar5/>
            </SvarModal>
            <SvarModal isDark={false} spørsmål={"Kan jeg søke studier etter 15.april?"}>
                <Svar6/>
            </SvarModal>
            <SvarModal isDark={true} spørsmål={"Hvilken dokumentasjon må jeg laste opp på samordnaopptak?"}>
                <Svar7/>
            </SvarModal>
        </div>
        </Scrollbars>
        <NavigFooter/>
    </div>;

export default SpørsmålOgSvar;
