import React from 'react';
import {Header} from '../common';
import {withRouter} from "react-router-dom";
import {useHistory} from 'react-router';
import Epost from './Epost';
import './Hovedsiden.css';
import '../common/CommonStyles.css';
import Stier from "../navigator/Stier";
import NotatModal from "../notat/NotatModal";


function Hovedsiden() {

    const history = useHistory();

    return <div className="page">
        <Header tekst={'7 trinn til utdanning'}/>
        <div className="hs-body">
            <div className="layout">
                <button className="boks rad1"
                        onClick={() => history.push(Stier.hva)}>
                    <div className="knappTekst hvit">
                        Hva skal jeg studere
                    </div>
                </button>
                <button className="boks rad1"
                        onClick={() => history.push(Stier.hvor)}>
                    <div className="knappTekst hvit">
                        Hvor skal jeg studere
                    </div>
                </button>
            </div>
            <div className="layout">
                <button className="boks rad2"
                        onClick={() => history.push(Stier.opptakskrav)}>
                    <div className="knappTekst hvit">
                        Opptakskrav
                    </div>
                </button>
                <button className="boks rad2"
                        onClick={() => history.push(Stier.karaktersnitt)}>
                    <div className="knappTekst hvit">
                        Regne ut karaktersnitt
                    </div>
                </button>
            </div>
            <div className="layout boxHeight">
                <button
                    className="boks rad3"
                    onClick={() => history.push(Stier.hvordan)}>
                    <div className="knappTekst svart">
                        Hvordan søke
                    </div>
                </button>
                <button
                    className="boks rad3"
                    onClick={() => history.push(Stier.støtte)}>
                    <div className="knappTekst svart">
                        Økonomisk støtte
                    </div>
                </button>
            </div>

            <div className="layout boxHeight">
                <button
                    className="boks rad4"
                    onClick={() => history.push(Stier.spørsmål)}>
                    <div className="knappTekst svart">
                        Vanlige spørsmål
                    </div>
                </button>
                <div className="boks rad4 modalBoks">
                    <NotatModal hovedSide/>
                </div>
            </div>
        </div>
        <Epost/>
    </div>
}

export default withRouter(Hovedsiden);