"use strict";

import {forbedretSkolePoeng, oppdaterKnapper, oppdaterTekster} from './ValgFunksjoner';
import {erGrønn, erGul, erRød, Farge, Knapp, Tekst} from "./Typer";

export function profilOver22Aar(knapper:Array<Knapp>, knappIndex:number, tekster:Array<Tekst>){
  if(knappIndex === 3){
    bestattVDG(knapper, tekster);
    return;
  }
  forbedretSkolePoeng(knapper, tekster);
}

function bestattVDG(knapper:Array<Knapp>, tekster:Array<Tekst>){
  if(erGul(knapper[3])){
    return;
  }
  if(erRød(knapper[3])){
    oppdaterKnapper(knapper, [5,6],false, Farge.Gul);
    oppdaterTekster(tekster, [5, 7, 9], false)
    tekster[2].vis=true;
    return;
  }
  if(erGrønn(knapper[3])){
    tekster[2].vis=false;
    tekster[5].vis=true;
    oppdaterKnapper(knapper, [5,6],true, Farge.Gul);
  }
}
