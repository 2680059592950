export enum Farge {
    Gul,
    Grønn,
    Rød
}

export interface Knapp {
    index: number,
    txt: string,
    vis: boolean,
    farge: Farge
}

export const erGul = (knapp:Knapp) => {return knapp.farge === Farge.Gul}
export const erGrønn = (knapp:Knapp) => {return knapp.farge === Farge.Grønn}
export const erRød = (knapp:Knapp) => {return knapp.farge === Farge.Rød}
export const endreFarge = (knapp:Knapp, farge:Farge) => {knapp.farge = farge}
export const viseKnapp = (knapp:Knapp) => {return knapp.vis === true}

export interface Tekst {
    "index": number,
    "txt": string,
    "vis": boolean
}

export const viseTekst = (tekst:Tekst) => {return tekst.vis === true}


