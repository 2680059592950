import React from 'react';
import {GlobalTekst, Header, VimeoEmbed} from '../../common';
import UrlKnapp from "../../common/UrlKnapp";
import NavigFooter from "../../navigator/NavigFooter";
import {Scrollbars} from "react-custom-scrollbars-2";

const knappBredde = "60%";

const Karaktersnitt = () =>
    <div className={"page"}>
        <Header tekst={GlobalTekst.karaktersnitt.overskrift}/>
        <div className={"body"}>
            <Scrollbars>
                <p className={"infoTekst"}>
                    Lurer du på hvordan du regner ut hvilket karaktersnitt du har?
                </p>
                <UrlKnapp url={'https://www.samordnaopptak.no/info/opptak/poengberegning/index.html'}
                          navn={'Samordna opptak: poengberegning'}
                          knappBredde={knappBredde}/>

                <VimeoEmbed videoId={"203116215"}/>

                <p className={"infoTekst"}>
                    Karakterkalkulatoren finner du her:
                </p>
                <UrlKnapp url={'https://utdanning.no/karakterkalkulator/#/'} navn={'Karakterkalkulator'}
                          knappBredde={knappBredde}/>
                <p className={"infoTekst"}>
                    Husk at realfagspoeng ikke teller ved opptak i utlandet.
                </p>
                <div className="emptyLine"/>
            </Scrollbars>
        </div>
        <NavigFooter/>
    </div>;

export default Karaktersnitt;
