const GlobalTekst = {

    hva: {
        overskrift: '1. Hva skal jeg studere?',
        tester: 'Tester',
        fagomr: 'Fagområder',
        friAar: 'Friår-mellomår',
        tilbud: {
            navTekst: 'Studietilbud',
            overskrift: 'Oversikt over studietilbud'
        },
        system: {
            navTekst: 'Utdanningssystemet',
            overskrift: 'Utdanningssystemet i Norge'
        },
        hjerneHjerte: 'Hjerne/hjerte'
    },
    hvor: {
        overskrift: '2. Hvor skal jeg studere?',
        utland: 'Utland',
        utlandNorsk: 'Studier på norsk i utlandet',
        uniHskole: 'Universitet og Høyskole',
        privat: 'Private høyskoler',
        fagskole: 'Fagskole',
        forsvar: 'Forsvaret'
    },
    opptakskrav: {
        overskrift: '3. Hva er opptakskravet?'
    },
    karaktersnitt: {
        overskrift: '4. Hvordan regne ut karaktersnitt?'
    },
    hvordan: {
        overskrift: '5. Hvordan søke?',
        soknadNorge: 'Søknad Norge',
        opptakskvoter: 'Ulike opptakskvoter',
        soknadUtland: 'Søknad Utland',
        mittOpptak: 'Finn din opptakskvote'
    },
    støtte: {
        overskrift: '6. Hva kan jeg få i økonomisk støtte'
    },
    spørsmål: {
        overskrift: '7. Vanlige spørsmål i valgprosessen'
    }

};

export {GlobalTekst};
